// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("D:\\workspace\\squarecookie\\homepage\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("D:\\workspace\\squarecookie\\homepage\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-landing-page-jsx": () => import("D:\\workspace\\squarecookie\\homepage\\src\\pages\\LandingPage\\LandingPage.jsx" /* webpackChunkName: "component---src-pages-landing-page-landing-page-jsx" */),
  "component---src-pages-landing-page-sections-product-section-jsx": () => import("D:\\workspace\\squarecookie\\homepage\\src\\pages\\LandingPage\\Sections\\ProductSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-product-section-jsx" */),
  "component---src-pages-landing-page-sections-project-section-jsx": () => import("D:\\workspace\\squarecookie\\homepage\\src\\pages\\LandingPage\\Sections\\ProjectSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-project-section-jsx" */),
  "component---src-pages-landing-page-sections-team-section-jsx": () => import("D:\\workspace\\squarecookie\\homepage\\src\\pages\\LandingPage\\Sections\\TeamSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-team-section-jsx" */),
  "component---src-pages-landing-page-sections-work-section-jsx": () => import("D:\\workspace\\squarecookie\\homepage\\src\\pages\\LandingPage\\Sections\\WorkSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-work-section-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "D:\\workspace\\squarecookie\\homepage\\.cache\\data.json")

